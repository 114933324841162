:root {
  overflow: hidden;
  cursor: url("../public/images/cursor.svg") 15 15, pointer;
}

body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

canvas {
  height: 100vh;
  width: 100vw;
  margin: 0;
  top: 0;
  left: 0;
  position: absolute;
}

.cursorTrail {
  z-index: 2;
}

.lightMode {
  background-color: #FFFDFD;
}

.darkMode {
  background: radial-gradient(#0b1628, #0c0921);
}

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.campsite {
  background-image: url("../public/images/landscape.png");
  aspect-ratio: 1928 / 830;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0px;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.campsiteDark {
  background-image: url("../public/images/landscape-dark.png");
  aspect-ratio: 1928 / 830;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0px;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.hidden {
  display: none;
}

.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
}

.cursor {
  position: absolute;
  top: -15px;
  left: -15px;
  width: 30px;
  height: 30px;
  pointer-events: none;
  z-index: 3;
}

.themeToggle {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 15px;
  z-index: 3;
}

.soundToggle {
  position: absolute;
  top: 70px;
  right: 30px;
  font-size: 15px;
  z-index: 3;
}

.smokeWrap {
  position: absolute;
  right: 24.5%;
  width: 10vw;
  bottom: 10vw;
}

.smoke,
.smoke2,
.smoke3 {
  filter: blur(5px);
  transform-origin: 50% 50%;
  width: 100%;
}

.smoke {
  animation: smoke1 3s linear infinite;
  animation-delay: 0.5s;
}

.smoke2 {
  animation: smoke2 3s linear infinite;
  animation-delay: 1.5s;
}

.smoke3 {
  animation: smoke3 4s linear infinite;
  animation-delay: 2.5s;
}

@keyframes smoke1 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(-1, 1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-10px) scale(-1, 1.05);
    opacity: 0.5;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-20px) scale(-1, 1.1);
    opacity: 1;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-30px) scale(-1, 1.15);
    opacity: 0.5;
  }

  100% {
    filter: blur(7px);
    transform: translateY(-40px) scale(-1, 1.2);
    opacity: 0;
  }
}

@keyframes smoke2 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-10px) scale(1.05);
    opacity: 0.5;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-20px) scale(1.1);
    opacity: 1;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-30px) scale(1.15);
    opacity: 0.5;
  }

  100% {
    filter: blur(7px);
    transform: translateY(-40px) scale(1.2);
    opacity: 0;
  }
}

@keyframes smoke3 {
  0% {
    filter: blur(0px);
    transform: translateY(0px) scale(1);
    opacity: 0;
  }

  25% {
    filter: blur(3px);
    transform: translateY(-20px) scale(1.05);
    opacity: 0.5;
  }

  50% {
    filter: blur(5px);
    transform: translateY(-40px) scale(1.1);
    opacity: 1;
  }

  75% {
    filter: blur(5px);
    transform: translateY(-60px) scale(1.15);
    opacity: 0.5;
  }

  100% {
    filter: blur(7px);
    transform: translateY(-80px) scale(1.2);
    opacity: 0;
  }
}